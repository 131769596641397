<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="Clause" ></m-header>
    <div class="main">
        <h1>
            <span>MC INTERNATIONAL ENTERPRISES LTD. </span>
        </h1>
        <p>
            <span >&nbsp;</span>
        </p>
        <p>
            <strong><span class="f-s-20">Return &amp; Refund Policy</span></strong>
        </p>
        <p>
            <span >Thank you for shopping at Mooby Yoho.</span>
        </p>
        <p>
            <span >If you are not entirely satisfied with your purchase, we&#39;re here to help.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="font-s-20">1.<span class="f-s-9">&nbsp;&nbsp;&nbsp; </span></span></strong><strong><span class="font-s-20">Quality Issues</span></strong>
        </p>
        <p>
            <span >While it is always our intention to ship only quality merchandise, we realize that off quality product may occasionally be shipped unintentionally. We will rectify product quality problems as quickly as possible with minimal inconvenience to a customer. Returns requested by the customer due to defective items based on no fault of MOOBYYOHO will be handled immediately in a manner that minimizes the expense and inconvenience of the customer. Please noted that these returned items must be in new condition with original packaging without damages or scratches. Once you have obtained the return number, you will given our courier contact to return the package back to us. MOOBYYOHO will pay the return shipping costs. Items can also be return in person at MOOBYYOHO office in Richmond. Once MOOBYYOHO has received the returned items, refund will be issued only on item(s) returned in compliance with the MOOBYYOHO Return &amp; Return Policy and Term of Conditions. Your refund will be credited to the original method of payment within 3 business days after receipt of the product. For customers paid with WeChat and AliPay, your refund will be issued based on the exchange rate from the same day. </span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="font-s-20">2.<span class="f-s-9">&nbsp;&nbsp;&nbsp; </span></span></strong><strong><span class="font-s-20">Shipping Damage</span></strong>
        </p>
        <p>
            <span >For standard carrier shipments, once you have determined that the product shipped to you were received damaged, please notify us and the carrier in person before signing the receipt of the package. At time of delivery, please inspect your shipment immediately upon arrival and note ANY damages to your shipment, including damage/breakage to the shipping box/products. If the packaging appears,damaged on the outside, please refuse to accept and sign the receipt, and let us know immediately.&nbsp; MOOBYYOHO will arrange a refund or reschedule your shipment upon receipt of your returned item(s). If you choose a refund, your refund will be credited to the original method of payment within 3 business days after receipt of the product. For customers</span> <span >paid with WeChat and AliPay, your refund will be issued based on the exchange rate from the same day.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="font-s-20">3.<span class="f-s-9">&nbsp;&nbsp;&nbsp; </span></span></strong><strong><span class="font-s-20">Received Wrong Item(s)</span></strong>
        </p>
        <p>
            <span >If you received the wrong items, please ensure that the items are in its original packaging, with all original packaging materials included. Once you submit a return request thru our platform, a return label code and courier&#39;s contact will be given to you by our staffs to assist you sending the item(s) back .Once MOOBYYOHO Warehouse received and inspected the item back from you, we will notify you by email of the approval or rejection of your refund whether the return items fulfill our compliance with the Terms &amp; Conditions. The refund will be returned to your payment account within 3 business days after receipt of the product. For customers paid with WeChat and AliPay, the refund will be calculated based on the exchange rate from the same day.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="font-s-20">4.<span class="f-s-9">&nbsp;&nbsp;&nbsp; </span></span></strong><strong><span class="font-s-20">Missing Item(s)</span></strong>
        </p>
        <p>
            <span >If you believe there is missing item(s), please submit a missing item(s) request and&nbsp; contact our customer service for assistance, we will re-ship your missing items upon the successful completion of the Claims process. We will cover the cost of the shipping for you.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="font-s-20">5.<span class="f-s-9">&nbsp;&nbsp;&nbsp; </span></span></strong><strong><span class="font-s-20">Sale Items</span></strong>
        </p>
        <p>
            <span >Sale items can be refunded.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="font-s-20">6.<span class="f-s-9">&nbsp;&nbsp;&nbsp; </span></span></strong><strong><span class="font-s-20">Return Policy</span></strong>
        </p>
        <p>
            <span >If you&#39;d like to return the product for reasons other than quality issues, incorrect item(s), missing item(s), please click on the return application and fill out the product return application form in accordance with the requirements within 7 days after the receipt of product. Please ensure that the items are in its original packaging, with all original packaging materials included. Once MOOBYYOHO Warehouse received and inspected the item back from you, we will notify you by email of the approval or rejection of your refund whether the return item(s) fulfill our compliance with the Terms &amp; Conditions. Once you are approved, your refund will be returned to your payment account within 3 business days after receipt of the product. For customers paid with WeChat and AliPay, the refund will be calculated based on the exchange rate of the same day.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="m-l">
            <strong><span class="f-s-24">7.<span class="f-s-9">&nbsp; </span></span></strong><strong><span class="f-s-24">Additional Rules</span></strong>
        </p>
        <p>
            <span >If you are requesting a return or refund because of quality issues of the product, Please ensure that the items are in its original packaging, with all original packaging materials included.If the items are nonreturnable, damaged or lost during return shipping due to reasons caused by the customers, the seller is entitled to deduct a portion of the refund for loss of value.</span>
        </p>
        <p>
            <span >Important Notice</span><span >（The following categories are not eligible under the 7-day Hassle-Free Returns policy）</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p>
            <strong><span class="f-s-20">Returns and refunds for these following Items are not allowed:</span></strong>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p>
            <span >①Customized products: personalized items, design services</span>
        </p>
        <p>
            <span >②Perishable products: flowers, green plant, aquatic and meat products, fresh fruits and vegetables,.</span>
        </p>
        <p>
            <span >③Downloadable contents or consumer-unpacked audio/visual products, computer software and other digital goods.</span>
        </p>
        <p>
            <span >④Delivered newspapers, periodicals.</span>
        </p>
        <p>
            <span >⑤Goods that will become harmful or hazardous to the safety or wellness of beings when unpacked, or unpacking of goods can easily lead to changes in product quality. (e.g. personal products, food, healthcare products, sex products, etc.) (Except items eligible under the 7-day Hassle-Free Returns policy authorized by MoobyYoho)</span>
        </p>
        <p>
            <span >⑥Goods or commercial goods that will take big value depreciation after activation or trial use, or goods that will show frequent fluctuations in short-term value (such as smart devices, kitchen appliances for commercial purposes, gold, etc.). </span>
        </p>
        <p>
            <span class="font-s-20">&nbsp;</span>
        </p>
        <p>
            <strong><span class="f-s-s">(Except items eligible under the 7-day Hassle-Free Returns policy authorized by MoobyYoho)</span></strong>
        </p>
        <p>
            <strong><span class="f-s-s">&nbsp;</span></strong>
        </p>
        <p class="t-a-l">
            <strong><span class="font-s-20">Rules and Guidelines for Condition of Returned Items:</span></strong>
        </p>
        <p>
            <strong><span class="f-s-f">&nbsp;</span></strong>
        </p>
        <p>
            <span >①Buyers should ensure that the returned items and related accessories (such as tags, instructions, warranty cards, etc.) must be returned in the same condition in which it was received, and it must include all items that were in the original package.The item(s) shall not be damaged, contaminated, anti-falsification label scratched and activated (authorization granted), etc. No traces of usage on the original appearance and should not show any traces of use.</span>
        </p>
        <p>
            <span >②All items must be returned unused in their original condition with the factory seal and packaging intact without serious damage or contamination;</span>
        </p>
        <p>
            <span >③The lost or damaged of items, or the loss of invoices does not affect the return of items. Small fees will be applied for lost and damaged items, and the customer will be responsible for small fees for lost invoices.Note: &quot;the exterior packaging&quot; means the original packaging from the manufacturer (minimum sales unit), the packaging from the seller or the shipping courier does not counted as the original packaging.</span>
        </p>
        <p>
            <span >④If product mismatch the quality issue, we will deduct corresponding shipping fee. &nbsp;</span>
        </p>
        <p>
            <span >Rules and Guidelines Condition of Returned Specialty Items :</span>
        </p>
        <p>
            <span >①Sneakers: Sneakers that are returned only accept if they are returned in original and unworn condition.The sneakers should not show any traces of use.</span>
        </p>
        <p>
            <span >②Cleaning agent/sanitary napkin/paper towel/aromatherapy product: Disposable packaging for cleaning products (if applicable) must be sealed and in original condition.</span>
        </p>
        <p>
            <span >③Hair care product/wig: The disposable sealed packaging for Hair Care product/Wig (if applicable) must be kept sealed and in original condition.</span>
        </p>
        <p>
            <span >④Make-up/perfume/beauty tools: Disposable sealed packaging for Make-up/perfume/beauty tools (if applicable) must be kept sealed and in original condition.</span>
        </p>
        <p>
            <span >⑤Digital/home appliance (except cameras, major appliances): 1. Network access license must be in original condition 2. Items should not show traces of water damages.</span>
        </p>
        <p>
            <span >⑥Digital camera/SLR camera/camera: The shutter for SLR cameras must not used more than 20 times.</span>
        </p>
        <p>
            <span >⑦Major appliance: 1. LCD TV &amp; air conditioner are only eligible for return after trail use, appliances must not show any traces of any kind of installation 2. Washing machine should not show traces of any use.</span>
        </p>
        <p>
            <span >⑧ZIPPO/Swiss army knife/glasses: Zippo merchandise returned for refund must be in new and unused condition. The items must not show any traces of use.</span>
        </p>
        <p>
            <span >⑨Figures/anime/COS/board game: Figures/anime/COS/board game must be returned in the same condition in which it was received, and must include all items that were in the original package.</span>
        </p>
        <p>
            <span >⑩Electronic components:All electriconic components must be returned in the same condition in which it was received, and must kept sealed in the original package.</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p>
            <strong><span class="f-s-f">Contact us</span></strong>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p>
            <span >If you have any questions about our Returns and Refunds Policy, please contact us:</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="t-a-l">
            <strong><span class="font-s-20">By email</span></strong><span class="f-s-20">:</span> <span >customerservice@moobyyoho.com</span>
        </p>
        <p class="t-a-l">
            <span >Return and Refund Policy of www.moobyyoho.com</span>
        </p>
        <p class="t-a-l">
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p class="t-a-l">
            <span >To obtain a Return Merchandise Number (RMN), contact us: </span>
        </p>
        <p>
            <span >By email: customerservice@moobyyoho.com</span>
        </p>
        <p>
            <span >Send the product with its original packing and the RMA number, to: </span>
        </p>
        <p>
            <span >Unit 133-11121 Horseshoe Way Richmond, BC V7A 5G7, Canada)</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
        <p>
            <span class="f-s-24">&nbsp;</span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    h1{line-height:50px;
        span {font-size:24px;line-height:6%;color:black}
    }
    p{margin:10px 0;}
    .f-s-20{font-size:20px;font-family: '&#39;微软雅黑&#39;,&#39;sans-serif&#39';}
    .f-s-9{font:9px '&#39;Times New Roman&#39';}
    .font-s-20{font-size: 20px;}
    .f-s-24{font-size: 24px;}
    .f-a-l{text-align: left;}
    .m-l{margin-left:0;text-indent:0}
    .f-s-f{font-size:24px;font-family: '&#39;微软雅黑&#39;,&#39;sans-serif&#39';}
    .f-s-s{font-size:21px;font-family: '&#39;微软雅黑&#39;,&#39;sans-serif&#39';}
  }
}
</style>